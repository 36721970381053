import { API_HOST } from '~/constants';

export const API_ENDPOINT_NAMES = {
  me: 'me',
  quote: 'quote',
  quotes: 'quotes',
  offers: 'offers',
  quotesRequest: 'quote-requests',
  invoices: 'customer-invoices',
  recommendedProducts: 'inventory/products/recommended',
  fulfillmentEvents: 'fulfillment-events',
  fulfillmentEvent: 'fulfillment_event',
  deliveries: 'fulfillment-events/search',
  translations: 'translations/locale',
  owners: 'owners',
  order: 'order',
  orders: 'orders',
  ordersNew: 'orders/search',
  search: 'search',
  inventory: 'inventory',
  claims: 'v2/claims',
} as const;

const ENDPOINT = {
  me: '/me',
  quotes: '/quotes',
  offers: '/quotes/offers',
  quotesRequest: '/quote-requests',
  invoices: '/customer-invoices',
  recommendedProducts: '/inventory/products/recommended',
  fulfillmentEvents: '/fulfillment-events',
  fulfillmentEvent: '/fulfillment-events',
  deliveries: '/fulfillment-events/search',
  translations: '/translations/locale',
  owners: '/owners',
  orders: '/orders',
  ordersNew: '/orders/search',
  search: '/search', // endpoint to search for offers(quotes)/orders/deliveries(fullfilment-events)
  inventory: '/inventory',
  claims: '/v2/claims',
  notifications: '/notifications',
} as const;

type EndpointKeys = keyof typeof ENDPOINT;

/**
 * API endpoints
 * - me: /me
 * - quotes: /quotes
 * - offers: /quotes/offers
 * - quotesRequest: /quote-requests
 * - invoices: /customer-invoices
 * - recommendedProducts: /inventory/products/recommended
 * - fulfillmentEvents: /fulfillment-events/search
 * - deliveries: /fulfillment-events
 * - translations: /translations/locale
 * - owners: /owners
 * - orders: /orders
 * - inventory: /inventory
 * - claims: /v2/claims
 */
export const API_ENDPOINT = (Object.keys(ENDPOINT) as EndpointKeys[]).reduce(
  (acc, key) => {
    acc[key] = `${API_HOST}${ENDPOINT[key]}`;
    return acc;
  },
  {} as Record<EndpointKeys, string>
);

export const getOfferPdfEndpoint = (id: string) =>
  `${API_ENDPOINT.quotes}/${id}/offer-pdf`;

export const getBillingDocumentLink = (id: string) =>
  `/api/home/billing/${id}/documents`;

export const AndercoreExternalLinks = {
  de: {
    privacy: 'https://andercore.com/de/datenschutz/',
    imprint: 'https://andercore.com/de/impressum/',
    terms: 'https://andercore.com/de/agb/',
  },
  en: {
    privacy: 'https://andercore.com/en/privacy-policy/',
    imprint: 'https://andercore.com/en/imprint/',
    terms: 'https://andercore.com/en/terms/',
  },
  fr: {
    privacy: 'https://andercore.com/fr/politique-de-confidencialite/',
    imprint: 'https://andercore.com/fr/mentions-legales/',
    terms: 'https://andercore.com/fr/cgv/',
  },
  it: {
    privacy: 'https://andercore.com/it/politique-de-confidencialite/',
    imprint: 'https://andercore.com/it/mentions-legales/',
    terms: 'https://andercore.com/it/cgv/',
  },
  es: {
    privacy: 'https://andercore.com/es/politique-de-confidencialite/',
    imprint: 'https://andercore.com/es/mentions-legales/',
    terms: 'https://andercore.com/es/cgv/',
  },
};
